<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto" style="max-width: fit-content;">
      <div style="display: flex">
        <h1
          style="width: calc(100% - 100px);"
          class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
        >
          Favourite Events
        </h1>
      </div>

      <div class="mb-4">
        <!-- component -->
        <div id="recipients" class="">
          <table
            id="tickets"
            class="border-collapse stripe hover w-full text-sm"
            style="overflow: scroll;
            overflow-x: scroll;
            display: block;
            position: initial;"
            v-if="data.length"
          >
            <thead>
              <tr>
                <th data-priority="1">
                  Event
                </th>
                <th data-priority="2">
                  User
                </th>
                <th data-priority="3">
                  TM Link
                </th>
                <th data-priority="4">
                  TP Link
                </th>
                <th data-priority="5">
                  Delete
                </th>
                <th data-priority="6">
                  Sync
                </th>
                <th data-priority="7">
                  Add
                </th>
                <th data-priority="8">
                  Venue
                </th>
                <th data-priority="9">
                  City
                </th>
                <th data-priority="10">
                  State
                </th>
                <th data-priority="11">
                  Performer Id
                </th>
                <th data-priority="12">
                  Category
                </th>
                <th data-priority="13">
                  Avail Tixs
                </th>
                <th data-priority="14">
                  Total Value
                </th>
                <th data-priority="15">
                  Avg Value
                </th>
                <th data-priority="16">
                  Last Event Date
                </th>
                <th data-priority="17">
                  Dates
                </th>
                <th data-priority="18">
                  Tix Sold In Date
                </th>
                <th data-priority="19">
                  Avg Sale Price
                </th>
                <th data-priority="20">
                  Time
                </th>
              </tr>
            </thead>
            <tbody id="ticketsList">
              <tr v-for="tr in data" :key="tr.id" id="tix-data-wait">
                <td>{{ tr.event }}</td>
                <td>{{ tr.user_id }}</td>
                <td>{{ tr.event }}</td>
                <td>{{ tr.event }}</td>
                <td @click="deleteMode(tr)">
                  <i class="fa fa-trash" style="font-size:2em;"></i>
                </td>
                <td @click="syncMode(tr)">
                  <i class="fa fa-refresh" style="font-size:2em;"></i>
                </td>
                <td @click="addMode(tr)">
                  <i class="fa fa-plus" style="font-size:2em;"></i>
                </td>
                <td>{{ tr.venuename }}</td>
                <td>
                  {{ tr.city }}
                </td>
                <td>
                  {{ tr.state }}
                </td>
                <td>
                  {{ tr.performerid }}
                </td>
                <td>
                  {{ tr.category }}
                </td>
                <td>
                  {{ tr.availabletixs }}
                </td>
                <td>
                  {{ tr.totalvalue }}
                </td>
                <td>
                  {{ tr.avgvalue }}
                </td>
                <td>
                  {{ tr.lasteventdate }}
                </td>
                <td>
                  {{ tr.dates }}
                </td>
                <td>
                  {{ tr.tixsoldindaterange }}
                </td>
                <td>
                  {{ tr.avgsaleprice }}
                </td>
                <td>
                  {{ tr.createdate }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!data.length" class="text-center">
            {{ loading }}
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="showModalFlag"
      title="Add Manual Event"
      ok-title="Save"
      cancel-title="Close"
      hide-footer
    >
      <!-- Modal Content -->
      <form @submit.prevent="saveEvent">
        <div class="form-group">
          <label for="ename">Details:</label>
          <textarea
            v-model="ename"
            type="text"
            id="ename"
            class="form-control"
            rows="2"
            required
          >
          </textarea>
        </div>
        <div class="form-group">
          <label for="saletype">Sale Type:</label>
          <input
            v-model="saletype"
            type="text"
            id="saletype"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label for="tmurl">Url:</label>
          <input
            v-model="tmurl"
            type="text"
            id="tmurl"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label for="datetime">Date and Time:</label>
          <input
            v-model="datetime"
            type="datetime-local"
            id="datetime"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label for="colorDropdown">Choose a Color:</label>
          <select
            v-model="color"
            id="colorDropdown"
            class="form-control"
            required
          >
            <option value="" disabled>Select a color</option>
            <option v-for="c in colorlist" :value="c" :key="c">{{ c }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="timezone">Select a Timezone:</label>
          <select
            v-model="timezone"
            id="timezone"
            class="form-control"
            required
          >
            <option value="" disabled>Select a timezone</option>
            <option
              v-for="timezone in usaTimezones"
              :value="timezone"
              :key="timezone"
              >{{ timezone }}</option
            >
          </select>
        </div>
        <!-- <b-button @click="closeModal" variant="secondary">Cancel</b-button> -->
        <!-- <b-button type="submit" variant="primary">Save</b-button> -->
        <div class="modal-footer">
          <b-button @click="closeModal" variant="secondary">Cancel</b-button>
          <b-button type="submit" variant="primary">Save</b-button>
        </div>
      </form>
      <!-- Modal Footer Slot -->
    </b-modal>
  </main>
</template>

<script>
import * as moment from "moment";
import "viewerjs/dist/viewer.css";
import { BButton, BModal } from "bootstrap-vue";

export default {
  name: "FavouriteEvents",
  components: {
    BButton,
    BModal,
  },
  data() {
    return {
      showModalFlag: false,
      loading: "Please wait...",
      data: [],
      ctr: [],
      addLoading: -1,
      daterange: "",
      ename: "",
      saletype: "",
      tmurl: "",
      datetime: "",
      color: "",
      timezone: "",
      colorlist: [
        "red",
        "blue",
        "green",
        "yellow",
        "orange",
        "purple",
        "pink",
        "brown",
        "gray",
      ],
      usaTimezones: [
        "America/New_York",
        "America/Chicago",
        "America/Denver",
        "America/Los_Angeles",
        "America/Phoenix",
        "America/Anchorage",
        "America/Adak",
        "Pacific/Honolulu",
      ],
    };
  },
  created() {
    this.get();
  },
  methods: {
    showModal() {
      this.showModalFlag = true;
    },
    closeModal() {
      this.showModalFlag = false;
      this.resetForm();
    },
    resetForm() {
      this.name = "";
      this.datetime = "";
    },
    saveEvent() {
      // Process and save the event data to your data model or perform any other action here
      console.log("Saved name:", this.name);
      console.log("Saved tmurl:", this.tmurl);
      console.log("Saved datetime:", this.datetime);
      console.log("Saved color:", this.color);
      console.log("Saved timezone:", this.timezone);
      console.log("Current:", this.ctr.event);
      // Cconsole.log("Saved name:", this.name);lose the modal after saving

      this.$axios
        .post(`https://ticketmasteraccount.com/api/fav_addev_new`, {
          id: this.ctr.id,
          ename: this.ename,
          tmurl: this.tmurl,
          saletype: this.saletype,
          edate: this.datetime,
          timezone: this.timezone,
          color: this.color,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((result) => {
          //var re = JSON.parse(result);
          alert(result.data.message);
          //alert(re.message);
          //this.fa-heart.color = this.colors.green;
        })
        .catch((error) => console.log("error", error));
      this.closeModal();
    },
    deleteMode(tr) {
      //alert(JSON.stringify(tr));
      this.$axios
        .post(`https://ticketmasteraccount.com/api/deletefavourite_events_new`, {
          id: tr.id,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((result) => {
          //var re = JSON.parse(result);
          alert(result.data.message);
          window.location.reload();
          //alert(re.message);
          //this.fa-heart.color = this.colors.green;
        })
        .catch((error) => console.log("error", error));
    },
    syncMode(tr) {
      // alert(JSON.stringify(tr));
      this.$axios
        .post(`https://ticketmasteraccount.com/api/syncfavourite_events_new`, {
          id: tr.id,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((result) => {
          //var re = JSON.parse(result);
          alert(result.data.message);
          //alert(re.message);
          //this.fa-heart.color = this.colors.green;
        })
        .catch((error) => console.log("error", error));
    },
    addMode(tr) {
      // alert(JSON.stringify(tr));
      console.log(JSON.stringify(tr));
      this.ctr = tr;
      this.showModal(tr);
    },
    dateParse(dt) {
      return moment(moment(dt).utcOffset("-06:00")).format(
        "MMMM Do YYYY, hh:mm A"
      );
    },
    get() {
      //const daterangeVal = this.daterange ? `?daterange=${this.daterange}` : "";
      this.$axios
        .get(`https://ticketmasteraccount.com/api/favourite_events_new`)
        .then((res) => {
          //console.log(res);
          this.data = res.data.data;
          if (this.data.length === 0) {
            this.loading = "No data found";
          }
          if (!res.data.success) {
            this.loading = "No data found";
          }
          //Radhika - change
          // console.log("inside then faveventsnew");
          var self = this;
          var existTix = setInterval(() => {
            // if (self.jQuery("#tix-data-wait").length) {
            clearInterval(existTix);
            self.jQuery("#tickets").DataTable({
              retrieve: true,
              deferRender: true,
              responsive: true,
              aLengthMenu: [500],
              processing: true,
              ajax: {
                url: `https://ticketmasteraccount.com/api/favourite_events_new`,

                dataFilter: function(data) {
                  const records = JSON.parse(data);
                  const json = records.data;
                  console.log(json);
                  return JSON.stringify({
                    recordsTotal: records.total,
                    recordsFiltered: records.total,
                    data: json,
                  }); // return JSON string
                },
              },
              language: {
                processing:
                  "<i class='fa fa-refresh fa-spin' style='font-size:4em;margin-top:20%;'></i>",
              },
              serverSide: true,
              deferLoading: res.data.total,
              columns: [
                {
                  data: "event",
                  title: "Event",
                  render: function(data, _type, row) {
                    return `<a
                        class="cursor-pointer text-blue-600"
                        href="/admin/ticket-chart/${data}/${row.city}/${row.category}"
                        target="_blank"
                        >${data}</a
                      >`;
                  },
                },
                { data: "user_id" },
                {
                  data: "event",
                  title: "TM Link",
                  render: function(data, _type, row) {
                    return `<a
                        class="cursor-pointer text-blue-600"
                        href="/admin/test/${data}/${row.city}/${row.state}"
                        target="_blank"
                        ><img src="${require("@/assets/img/landing/ticket.jpeg")}" alt="img" style="max-width: 30px;"></a
                      >`;
                  },
                },
                {
                  data: "event",
                  title: "TP Link",
                  render: function(data) {
                    var name = data.replace("/", "-");
                    return `<a
                        class="cursor-pointer text-blue-600"
                        href="/admin/test1/${name}"
                        target="_blank"
                        ><img src="${require("@/assets/img/landing/tickpick.png")}" alt="img" style="max-width: 30px;"></a
                      >`;
                  },
                },
                {
                  data: "",
                  title: "Delete",
                  render: function() {
                    return `<a><i class='fa fa-trash' style='font-size:2em;'></i></a>`;
                  },
                },
                {
                  data: "",
                  title: "Sync",
                  render: function() {
                    return `<a><i class='fa fa-refresh' style='font-size:2em;'></i></a>`;
                  },
                },
                {
                  data: "",
                  title: "Add",
                  render: function() {
                    return `<a><i class='fa fa-plus' style='font-size:2em;'></i></a>`;
                  },
                },
                { data: "venuename" },
                { data: "city" },
                { data: "state" },
                { data: "performerid" },
                { data: "category" },
                { data: "availabletixs" },
                { data: "totalvalue" },
                { data: "avgvalue" },
                { data: "lasteventdate" },
                { data: "dates" },
                { data: "tixsoldindaterange" },
                { data: "avgsaleprice" },
                {
                  data: "createdate",
                  title: "Time",
                  render: function(data) {
                    return self.dateParse(data);
                  },
                },
              ],
            });
            // }
            // this.addCustomSelect();
          }, 100);
          console.log("hhhhhh");
        })
        .catch((err) => {
          console.log(err);
          this.loading = "Something went wrong, please try again";
        });
    },
  },
};
</script>

<style scoped>
/*Overrides for Tailwind CSS */

/*Form fields*/
.dataTables_wrapper select,
.dataTables_wrapper .dataTables_filter input {
  color: #4a5568; /*text-gray-700*/
  padding-left: 1rem; /*pl-4*/
  padding-right: 1rem; /*pl-4*/
  padding-top: 0.5rem; /*pl-2*/
  padding-bottom: 0.5rem; /*pl-2*/
  line-height: 1.25; /*leading-tight*/
  border-width: 2px; /*border-2*/
  border-radius: 0.25rem;
  border-color: #edf2f7; /*border-gray-200*/
  background-color: #edf2f7; /*bg-gray-200*/
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 3px !important;
}
table.dataTable {
  width: 100% !important;
  /*margin-left: -90px;*/
}

/*Row Hover*/
table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: #ebf4ff; /*bg-indigo-100*/
}

/*Pagination Buttons*/
.dataTables_wrapper .dataTables_paginate .paginate_button {
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Pagination Buttons - Current selected */
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #fff !important; /*text-white*/
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /*shadow*/
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  background: #667eea !important; /*bg-indigo-500*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Pagination Buttons - Hover */
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #fff !important; /*text-white*/
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /*shadow*/
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  background: #667eea !important; /*bg-indigo-500*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Add padding to bottom border */
table.dataTable.no-footer {
  border-bottom: 1px solid #e2e8f0; /*border-b-1 border-gray-300*/
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

/*Change colour of responsive icon*/
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  background-color: #667eea !important; /*bg-indigo-500*/
}
.r-1sreavd {
  fill: rgb(249, 24, 128);
}
.r-1xvli5t {
  height: 2em;
}
</style>
